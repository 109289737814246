import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AssetCarousel from '../AssetCarousel';
import API from '../../../API';
import handleLabels from '../../../utils/QRFunctions/handleLabels';
import { handleTextWithLinks } from '../../../utils/QRFunctions/handleTextWithLinks';
import styles from './AssetDisplay.module.css';

const AssetDisplay = (props) => {
    const { qrObj, asset, userObj, orgId, pdfLinks, setQrObj } = props;
    const { updateQR, storeFiles, deleteFile } = API;
    const { id } = useParams();
    const navigate = useNavigate();

    const [ randKey ] = useState(id);
    const [ editMode, setEditMode ] = useState(false);
    const [ files, setFiles ] = useState([]);
    const [ fileArr, setFileArr ] = useState(asset?.route ? asset?.route?.map(url => ({ url: url, raw: url })) : []);
    const [ removed, setRemoved ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState('');

    const acceptableFileTypes = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'application/pdf', 'video/mp4', 'video/webm', 'video/quicktime'];

    const handleBuildRoute = (route) => {
        if (route?.url?.includes('data:video')) {
            return <video controls src={route?.url} className={styles.thumbnail} alt='QR code file to upload'></video>;
        };

        if (route?.url?.includes('pdf')) {
            return (
                <>
                    <span className='mb-0'>{route?.url.split('.')[1]}</span>
                    <i className={route?.url?.includes('pdf') ? `fa-solid fa-file-pdf ${styles.display_icon}` : `fa-solid fa-image ${styles.display_icon}`} />
                </>
            );
        };

        if (route?.url?.includes('mov') || route?.url?.includes('webm') || route?.url?.includes('mp4')) {
            return <video controls src={route?.url} className={styles.thumbnail} alt='QR code file to upload'></video>;
        };

        if (route?.url?.includes('base64')) {
            return <img src={route?.url} className={styles.thumbnail} alt='QR code file to upload' ></img>;
        };

        return <img src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_files/${route?.url}?${Date.now()}`} className={styles.thumbnail} alt='QR code' ></img>
    };

    const handleClear = (arr) => {
        setEditMode(false);
        setFiles([]);
        setRemoved([]);
        setFileArr(arr?.map(url => ({ url: url, raw: url })));
        setLoading(false);
    };

    const handleEditAssetBtn = async () => {
        const arr = qrObj?.asset?.route ?? [];
        const handleFormData = (name) => {
            const formData = new FormData();
            files.forEach((file, i) => formData.append('file', file, `${name + i}`));
            return formData;
        };
        
        if (removed.length > 0) {
            removed.forEach(async (route) => {
                await deleteFile(route);
            });
        };

        if (files?.length > 0) {
            const name = Date.now();
            const updatedArr = arr.concat(...files.map((file, i) => `${id}/${name + i}.${file.name.split('.')[1]}`));
            await storeFiles(randKey, handleFormData(name));
            await updateQR(randKey, {data: {...qrObj, asset: { ...qrObj?.asset, route: updatedArr }}}).then(() => {
                setQrObj(prev => ({ ...prev, asset: { ...prev?.asset, route: updatedArr } }));
                handleClear(updatedArr);
            });
        } else {
            await updateQR(randKey, {data: {...qrObj, asset: { ...qrObj?.asset, route: arr }}}).then(() => {
                setQrObj(prev => ({ ...prev, asset: { ...prev?.asset, route: arr } }));
                handleClear(arr);
            });
        };
    };
    
    const handleRemoveAsset = async (route) => {
        if (route?.raw?.type) {
            setFiles(prev => prev.filter(x => x.name !== route?.raw?.name));
        };

        if (!route?.raw?.type && route?.raw?.includes(id)) {
            setRemoved(prev => prev.concat(route?.url));
        };
        
        setFileArr(prev => prev.filter(x => x.url !== route?.url));
        setQrObj(prev => ({ ...prev, asset: { ...prev?.asset, route: prev?.asset?.route.filter(x => x !== route?.url) } }));
    };

    const handleAddFiles = async (inputFilesArr) => {
        setError(() => '');

        if (fileArr?.length + inputFilesArr.length <= 5) {
            const uploadArr = [];

            const promiseReader = async (file) => {
                return new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        resolve(e.target.result);
                    };
                    reader.readAsDataURL(file);
                });
            };

            for (const file of inputFilesArr) {
                if (!acceptableFileTypes.includes(file.type) || file.size > 5000000) {
                    setError(!acceptableFileTypes.includes(file.type) ? 'File type not accepted.' : 'File size exceeds 5MB. File must be under 5MB.');
                } else {
                    uploadArr.push({url: await promiseReader(file), raw: file});
                };
            };

            setFiles(prev => prev.concat(...inputFilesArr));
            setFileArr(prev => prev.concat(...uploadArr));
        } else {
            setError('Maximum of 5 files allowed.');
        };
    };

    if (orgId === qrObj?.org_id && editMode) {
        return (
            <>
                <div className={styles.display}>
                    <button className={styles.display_name} onClick={() => setEditMode(false)}>Cancel</button>
                    <button className={styles.edit_save_btn} onClick={() => {setLoading(true); handleEditAssetBtn(); }} disabled={qrObj?.org_id !== orgId || loading} >{loading ? <i className='fa-duotone fa-spinner fa-spin' /> : 'Save'}</button>
                    <div className={styles.edit_input_container}>
                        <div className={styles.inputGroup}>
                            <input type='text' id='asset-display-edit-nickname' className={styles.input} value={qrObj?.nickname} onChange={e => setQrObj(prev => ({ ...prev, nickname: e.target.value })) } onBlur={handleLabels}></input>
                            <label htmlFor='asset-display-edit-nickname'className={qrObj?.nickname ? `${styles.floating_label} ${styles.filled}` : `${styles.floating_label}`}>Name</label>
                        </div>
                        <div className={styles.inputGroup}>
                            <div className={styles.fade} />
                            <textarea type='text' id='asset-display-edit-description' className={styles.asset_textarea} value={qrObj?.asset?.description} onChange={e => setQrObj(prev => ({ ...prev, asset: { ...prev?.asset, description: e.target.value } })) }></textarea>
                            <label htmlFor='asset-display-edit-description' className={qrObj?.asset?.description ? `${styles.floating_label} ${styles.filled}` : `${styles.floating_label}`}>Description</label>
                        </div>
                        <div className={styles.inputGroup}>
                            <div className={styles.fade} />
                            <textarea id='asset-display-edit-notes' className={styles.asset_textarea} value={qrObj?.asset?.notes} onChange={e => setQrObj(prev => ({ ...prev, asset: { ...prev?.asset, notes: e.target.value } })) } ></textarea>
                            <label htmlFor='asset-display-edit-notes' className={qrObj?.asset?.notes ? `${styles.floating_label} ${styles.filled}` : `${styles.floating_label}`}>Notes</label>
                        </div>
                        <div className={styles.file_display}>
                            {fileArr?.length > 0 ?
                                fileArr.map((route, i) => {
                                    return (
                                        <div className={styles.file_container} key={i}>
                                            <div className={styles.file_container_inner}>
                                                {handleBuildRoute(route)}
                                            </div>
                                            <button className={styles.remove_menu} onClick={() => handleRemoveAsset(route)}><i className='fa-solid fa-xmark' /></button>
                                        </div>
                                    )
                                })
                                : files?.length < 1 && asset?.route?.length < 1 ?
                                    <div>There are no files associated with this QR.</div>
                                :
                                <></>
                            }
                        </div>
                        <div className={styles.file_upload_wrap}>
                            {asset?.route?.length >= 5 ? <div className={styles.max_message}>Maximum of 5 files has been reached.</div> : <></>}
                            <input type='file' id='asset-file-label' disabled={asset?.route?.length >= 5} className={styles.file_upload} multiple accept={acceptableFileTypes.join(',')} onChange={(e) => handleAddFiles(Object.values(e.target.files))} ></input>
                            <label htmlFor='asset-file-label' className={styles.file_label}>Add File</label>
                        </div>
                        <div className={styles.removed_added_files}>
                            {error ? 
                                <div>{error}</div>
                            :
                                <div style={{lineHeight: '1.5rem', whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}} className='mb-1'>Files Slot(s) Remaining: <span style={{fontWeight: 'bold'}}>{5 - Number(fileArr?.length ?? 0)}</span></div>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    };

    if (loading) {
        return <div>...Loading</div>
    };

    return (
        <div className={styles.display}>
            <div className={styles.header}>
                {qrObj?.org_id === userObj?.org_id ?
                    <>
                        <button className={styles.display_name} onClick={() => navigate(`/collection`, { state: { qrID: id } })}>Collection</button>
                        <button className={styles.edit_save_btn} onClick={() => setEditMode(true)} disabled={qrObj?.org_id !== userObj?.org_id} >Edit</button>
                    </>
                :
                    <div className={styles.display_name} >Liquid Label</div>
                }
                <h2 className={styles.nickname}>{qrObj?.nickname}</h2>
                <div className={styles.description_wrap}>
                    <div className={styles.description}>{asset?.description?.length > 0 ? handleTextWithLinks(asset?.description) : <></>}</div>
                </div>
                <div className={styles.notes_wrap}>
                    <div className={styles.notes}>{asset?.notes?.length > 0 ? handleTextWithLinks(asset?.notes) : <></>}</div>
                </div>
            </div>
            {asset?.route?.length > 0 ?
                <AssetCarousel asset={asset} pdfLinks={pdfLinks} />
            :
                <div>There are no files associated with this QR.</div>
            }
        </div>
    );
};

export default AssetDisplay;