import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTimezoneSelect, allTimezones } from 'react-timezone-select';
import SignupComponent from '../../components/SignupComponent';
import { useAuth } from '../../utils/Auth';
import { auth } from '../../firebase';
import API from '../../API';
import styles from './SignUp.module.css';
import GenExistingUser from '../../components/SignupComponent/GenExistingUser';

const createQrTemplate = ({ created_at, qr_style, id, redirect_url, org_id }) => {
    if (!qr_style || !Object?.keys(qr_style)?.length || !qr_style?.data || !id) throw('incomplete');

    return {
        active: true,
        alternate_redirect_url: [redirect_url],
        category: null,
        created_at: created_at,
        microsite: '',
        nickname: id,
        org_id: org_id,
        qr_style: qr_style,
        qr_type: 'link',
        redirect_url: redirect_url,
        schedule: null,
        tags: [],
        updated_at: Date.now()
    };
};

export default function SignUp() {
    // ----- STATE INITIALIZATION -----
    const { create } = useAuth();
    const navigate = useNavigate();
    const { addUIDtoDB, getGeneratorQr, addQrFromGenerator } = API;

    const query = window.location.search;
    const [ searchParams ] = useSearchParams();
    const gen_id = searchParams.get('gen_id');
    const emailQuery = searchParams.get('email');

    const [ email, setEmail ] = useState(emailQuery ? emailQuery : '');
    const [ password, setPassword ] = useState('');
    const [ isLoading, setIsLoading ] = useState(false);
    const [ state, setState ] = useState({
        qrGenObj: { qr_style: {}, created_at: 0, id: '' },
        error: '',
        loading: true
    });
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- LOGIN REDIRECT -----
    const loginRedirect = () => {
        if (query) {
            window.location.assign(window.location.origin + `/login${query}`);
        };
        navigate('/login');
    };
    // ----- END LOGIN REDIRECT -----
    // ----------------------------------------------

    const [ timezone ] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const [ formattedTimezone, setFormattedTimezone ] = useState({});
    const { options } = useTimezoneSelect('original', allTimezones);

    const handleGetGeneratorQr = useCallback(async () => {
        try {
            const qrObj = await getGeneratorQr(gen_id);
            const redirect_url = qrObj.qr_style.data;

            qrObj.qr_style.data = `https://lqr.ai/${gen_id}`
            qrObj.qr_style.type = 'svg';
            qrObj.qr_style.width = 128;
            qrObj.qr_style.height = 128;
            qrObj.qr_style.image = qrObj.qr_style.image ? `${qrObj.qr_style.image}?${Date.now()}` : '';
            qrObj.qr_style.imageOptions.imageSize = 0.4;
            
            setState({
                qrGenObj: { qr_style: qrObj.qr_style, id: qrObj.id, created_at: qrObj.created_at, redirect_url: redirect_url },
                error: '',
                loading: false
            });
        } catch (err) {
            if (err === 'Does not exist') {
                setState(prev => ({ ...prev, error: 'No QR', loading: false}))
            } else {
                setState(prev => ({ ...prev, error: 'Error retrieving QR.', loading: false}))
            };
        };
    },[gen_id]);

    useEffect(() => {
        if (timezone) {
            options.forEach(option => {
                if (option.value === timezone) {
                    setFormattedTimezone({label: option.label, offset: option.offset, value: option.value})
                };
            });
        };
    }, [timezone, options]);

    useEffect(() => {
        if (gen_id) {
            handleGetGeneratorQr();
        };
    },[gen_id]);

    // ----- SIGNUP FUNCTION -----
    const handleSignUp = async () => {
        setIsLoading(true);

        try {
            const userCredentials = await create(email, password);
            const uid = userCredentials.user.uid;

            const data = {
                categories: [],
                credit_balance: 0,
                org_id: uid,
                plan: {
                    analytics: 0,
                    categories: 1,
                    liquid_qrs: 1,
                    plan_id: 0,
                    support: 0,
                    team_size: 1
                },
                sonar_length: 3600000,
                team: [uid],
                timezone: formattedTimezone
            };

            await addUIDtoDB(data);
            
            if (gen_id) {
                const genQr = createQrTemplate({ ...state.qrGenObj, org_id: uid });
                
                await addQrFromGenerator({ id: gen_id, data: genQr });
            };

            if (query) {
                window.location.assign(window.location.origin + `/upgrade${query}`);
            };

            setIsLoading(false);
            navigate('/');
        } catch (err) {
            if (err === 'incomplete') {
                console.log('incomplete from catch');
            };

            console.log('err', err);
        };
    };
    // ----- END SIGNUP FUNCTION -----
    // ----------------------------------------------

    if (gen_id && auth?.currentUser && !state?.qrGenObj?.id && !state.loading) {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <div className={styles.nope}>
                        This QR does not exist or has already been converted. Head to your collection to view your QRs. 
                        <button onClick={() => navigate('/collection')}>Collection</button> 
                    </div>
                </div>
            </div>
        );
    } else if (gen_id && auth?.currentUser) {
        return (
            <div className={styles.wrapper} >
                <div className={styles.container}>
                    <GenExistingUser gen_id={gen_id} createQrTemplate={createQrTemplate} state={state} />
                </div>
            </div>
        );
    };

    return (
        <div className={styles.wrapper}>
            <SignupComponent gen_id={gen_id} loginRedirect={loginRedirect} handleSignUp={handleSignUp} email={email} setEmail={setEmail} password={password} setPassword={setPassword} isLoading={isLoading} state={state} setState={setState} />
        </div>
    );
};