import React, { useEffect, useState, useReducer } from 'react';
import SignupComponent from '../../components/SignupComponent';
import LoginComponent from '../../components/LoginComponent';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../utils/Auth';
import API from '../../API';
import { getDoc, doc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import qrOptions from '../../utils/QRFunctions/qrOptions';
import qrData from '../../utils/QRFunctions/qrData';
import LoadingSpinner from '../../components/LoadingSpinner';
import dataURLtoFile from '../../utils/QRFunctions/handleLogo';
import styles from './SetUp.module.css';
import EditQR from '../../components/QRSwitch/EditQR';

export default function SetUp() {
    // ----- STATE INITIALIZATION -----
    const { userObj, currentUser, create, login } = useAuth();
    const params = useParams();
    const { addUIDtoDB } = API;

    const [ authTypeBool, setAuthTypeBool ] = useState(true);
    const [ loading, setLoading ] = useState();
    const [ QR, setQR ] = useState({});
    const [ stickerId ] = useState(params.id);
    const [ step, setStep ] = useState(1);
    const [ categories, setCategories ] = useState([]);
    const [ qrLoad, setQrLoad ] = useState(true);
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ logo, setLogo ] = useState(null);
    const [ formData, setFormData ] = useState('');

    const navigate = useNavigate();

    const [qr_options, optionsDispatch] = useReducer((state, action) => {
        state = qrOptions(action, state);
        return state;
    }, null);

    const [qr_data, dataDispatch] = useReducer((state, action) => {
        if (action.type === 'set') {
            state = action.data;
        } else {
            state = qrData(action, state);
        };

        return state;
    }, { redirect_url: '', category: '', tags: [], nickname: '', menu: '', microsite: '', pdf: '', asset: '', tracker: { description:'', columns: [{ archived: false, id: 'date', name: 'date', required: true, options: [], type: 'date' }] }, alternate_redirect_url: [], liquid_link: '' });

    useEffect(() => {
        if (userObj && !categories.length) {
            setCategories(userObj.categories);
        };
    },[userObj, categories, setCategories]);
    
    useEffect(() => {
        if (logo) {
            optionsDispatch({type: 'logo', options: logo});
            setFormData(dataURLtoFile(logo, 'logo.png'));
        } else {
            setFormData(null);
            optionsDispatch({type: 'remove-logo', options: "M"});
        };
    },[logo]);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------
    
    useEffect(() => {
        if (stickerId) {
            const getQRfromDatabase = async () => {
                const snapshot = await getDoc(doc(firestore, 'codes', stickerId));
                if (snapshot.exists()) {
                    setQR({id: snapshot.id, data: snapshot.data()});
                    setQrLoad(false);
                } else {
                    setQrLoad(false);
                };
            };

            getQRfromDatabase();
            optionsDispatch({type: 'liquid', options: {randKey: stickerId}});
        };
    },[stickerId]);

    const handleReset = () => {
        navigate('/collection', { state: { qrID: stickerId }});
    };
    // ----- END HELPER FUNCTIONS -----
    // ----------------------------------------------

    // ----- LOGIN FUNCTION -----
    const handleLogin = async (e) => {
        setLoading(true);
        e.preventDefault();
        await login(email, password)
            .catch(err => {
                setLoading(false);
            });
    };
    // ----- END LOGIN FUNCTION -----
    // ----------------------------------------------

    // ----- SIGNUP FUNCTIONS -----
    const handleSignUp = () => {
        create(email, password)
            .then(userCredentials => {
                const uid = userCredentials.user.uid;
                const data = {
                    categories: [],
                    credit_balance: 0,
                    org_id: uid,
                    plan: {
                        analytics: 0,
                        categories: 0,
                        liquid_qrs: 1,
                        plan_id: 0,
                        support: 0,
                        team_size: 1
                    },
                    sonar_length: 3600000,
                    team: [uid]
                };
                addUIDtoDB(data).catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    };

    const loginRedirect = () => {
        const signupBtn = document.getElementById('signup-btn');
        signupBtn.classList.remove('setup-btn-clicked');
        setAuthTypeBool(true);
    };
    // ----- END SETUP FUNCTIONS -----
    // ----------------------------------------------

    // ----- RETURN CONDITIONALS -----
    const handleReturn = () => {
        if (currentUser) {
            if (QR && !qrLoad) {
                if (!QR?.data?.org_id) {
                    return (
                        <EditQR
                            QRtoEdit={QR} 
                            id={QR.id}
                            qr_options={qr_options} 
                            optionsDispatch={optionsDispatch}
                            qr_data={qr_data}
                            dataDispatch={dataDispatch}
                            handleReset={handleReset}
                            categories={categories}
                            setCategories={setCategories}
                            step={step}
                            setStep={setStep}
                            plan={userObj?.plan}
                            plan_id={userObj?.plan?.plan_id}
                            org_id={userObj?.org_id}
                            logo={logo}
                            setLogo={setLogo}
                            formData={formData}
                        />
                    );
                } else {
                    return (
                        <div className='d-flex justify-content-center mt-3'>
                            <h4>QR has already been initialized. Click <Link to='/collection'>here</Link> to view your collection.</h4>
                        </div>
                    );
                };
            } else {
                return <LoadingSpinner />;
            };
        } else if (currentUser && !qrLoad && !QR) {
            return(<h3>QR does not exist. Scan QR and follow the instructions on screen.</h3>)
        } else {
            if (authTypeBool) {
                return (<LoginComponent handleLogin={handleLogin} setUp={true} loading={loading} email={email} setEmail={setEmail} password={password} setPassword={setPassword} />);
            } else {
                return (<SignupComponent loginRedirect={loginRedirect} handleSignUp={handleSignUp} setUp={true} email={email} setEmail={setEmail} password={password} setPassword={setPassword} />);
            };
        };
    };
    // ----- END RETURN CONDITIONALS -----
    // ----------------------------------------------

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                {(currentUser && !userObj) ? 
                    // <LoadingSpinner />
                    <></>
                : (!currentUser && !userObj) ?
                    <div style={{width: '100%'}}>
                        <div className='d-flex justify-content-center'>
                            <h2>Register QR</h2>
                        </div>
                        <div className={styles.inputGroup} style={{justifyContent: 'center', marginBottom: '1.5rem'}} >
                            <input type='radio' className={styles.radio} onChange={() => setAuthTypeBool(true)} checked={authTypeBool === true} id='login-radio' />
                            <label htmlFor='login-radio' className={styles.radioLabel}>Log In</label>
                            <input type='radio' className={styles.radio} onChange={() => setAuthTypeBool(false)} checked={authTypeBool === false} id='signup-radio'/>
                            <label htmlFor='signup-radio' className={styles.radioLabel}>Sign Up</label>
                        </div>
                    </div>
                :
                    <></>
                }
                {handleReturn()}
            </div>
        </div>
    );
};