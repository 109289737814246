import React, { useState } from 'react';
import { customAlphabet } from 'nanoid';
import styles from './Categories.module.css';

export default function Categories(props) {
    // ----- STATE INITIALIZATION -----
    const { qr_data, dataDispatch, plan, categoryList, setCategoryList } = props;
    
    const nanoid = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzQWERTYUIOPASDFGHJKLZXCVBNM', 6);
    const [ addNewCat, setAddNewCat ] = useState(false);
    const [ newCatValue, setNewCatValue ] = useState({ id: '', name: '' });
    const [ categoryName, setCategoryName ] = useState(qr_data?.category ? categoryList?.find(cat => cat.id === qr_data?.category)?.name : '');
    const [ categoryValue, setCategoryValue ] = useState(qr_data?.category ?? '');
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- CATEGORIES -----
    const handleCategoryID = () => {
        let id = nanoid();
        if (categoryList.length > 0 && categoryList.find(cat => cat.id === id)) {
            id = handleCategoryID();
        };
        return id;
    };
    
    const handleAddCategoryName = async (name) => {
        setCategoryName(name);
        if (categoryValue === 'new' && !newCatValue?.id && name.length > 0 && plan.categories > (categoryList?.length ?? 0)) {
            const id = handleCategoryID();
            setNewCatValue(() => ({ id: id, name: name }));
            setCategoryList(prev =>  ([...prev, { id: id, name: name } ]))
            dataDispatch({type:'category', data: id});
            setCategoryValue(id);
        } else if (newCatValue?.id) {
            setNewCatValue(prev => ({ ...prev, name: name }));
            setCategoryList(prev => {
                return prev.map(cat => {
                    if (cat.id === newCatValue?.id) {
                        cat.name = name;
                    };
                    return cat;
                });
            });
        };
    };
    // ----- END CATEGORIES -----
    // ----------------------------------------------

    return (
        <>
        {/* <h5>Category</h5> */}
        <div className={styles.inputGroupSelect}>
            <select 
                className={styles.select}
                id='category-select'
                value={categoryValue}
                onChange={(e) => {
                    if (e.target.value !== 'new') {
                        setAddNewCat(false);
                        dataDispatch({type:'category', data: e.target.value});
                    } else {
                        setCategoryValue('');
                        setCategoryName('');
                        setNewCatValue({ id: '', name: '' });
                        setAddNewCat(true);
                    };
                    setCategoryValue(e.target.value)
                }}
            >
                <option value='0' disabled>-- Select Category --</option>
                {(plan.categories > (categoryList?.length ?? 0)) ?
                    <option value='new' key='custom' disabled={(plan.categories <= (categoryList?.length ?? 0))} >Add New Category</option>
                :
                    <></>
                }
                {categoryList && ((categoryList?.length ?? 0) > 0) ?
                    categoryList.map(cat => <option value={cat.id} key={cat.id}>{cat.name}</option>)
                :
                    <></>
                }
            </select>
        </div>
        {(addNewCat && plan.categories > 0) ?
            <div className={styles.inputGroup}>
                <input type='text' className={styles.input} id='category-name' value={categoryName} onChange={(e) => handleAddCategoryName(e.target.value)} required maxLength='30' />
                <label htmlFor='category-name' className={styles.label}>Enter New Category</label>
            </div>
        :
            <></>
        }
        </>
    );
};