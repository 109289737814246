import React, { useState } from 'react';
import { useAuth } from '../../utils/Auth';
import { Navigate } from 'react-router-dom';
import { getDoc, doc, collection, setDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import { getApp } from 'firebase/app';
import { getStorage, ref, uploadBytes} from 'firebase/storage';
import QRCodeStyling from 'pp-qr-code';
import handleLabels from '../../utils/QRFunctions/handleLabels';
import { badCodes } from '../../utils/BadCodes';
import styles from './Admin.module.css';

export default function Admin() {
    const { currentUser, userObj } = useAuth();

    const [ imageType, setImageType ] = useState(null);
    const [ num, setNum ] = useState(0);
    const [ csvLink, setCsvLink ] = useState(null);

    const app = getApp();
    const storage = getStorage(app);

    const keyGen = async () => {
        let arr = 'M1NqBaVz2CwXsZxA3eSdDcF4GrfHvJ5KtLgbP6OyIhUn7YuTjRmE8iWk9Qol0p';
        let randCode = '-';
        let idBool = true;
    
        while(idBool === true){
            for (let i = 0; i < 7; i++) {
                let randNum = Math.floor(Math.random() * 62);
                randCode += arr[randNum];
        
                if(i === 6){
                    const snapshot = await getDoc(doc(collection(firestore, 'codes'), randCode));
                    if (snapshot.exists() || badCodes.includes(randCode.toLowerCase())) {
                        randCode = '-';
                    } else {
                        idBool = false;
                        return randCode;
                    };
                };
            };
        };
    };

    const saveToDB = (key, obj) => {
        obj.width = 300;
        obj.height = 300;

        const data = {
            active: true,
            category: null,
            nickname: key,
            qr_type: 'link',
            qr_style: obj,
            redirect_url: `https://app.liquidqr.com/setup/${key}`,
            tags: []
        };

        const codesRef = doc(firestore, 'codes', key);
        setDoc(codesRef, data, {merge: true});
    };

    const saveImgToStorage = async (ref, file) => {
        await uploadBytes(ref, file).then(snapshot => {
            console.log(`Uploaded ${file.name}`);
        });
    };

    const handleCreate = async () => {
        if (num > 0) {
            const date = new Date();
            const formatted = `${+date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;
            const csv = [];
            for (let i = 0; i < num; i++) {
                const key = await keyGen();
                let data;

                if (imageType === 'scanMe') {
                    data = {
                        width: 300,
                        height: 300,
                        margin: 0,
                        qrOptions: {
                            typeNumber:"0",
                            mode:"Byte",
                            errorCorrectionLevel:"H"
                        },
                        data: `https://lqr.ai/${key}`,
                        imageOptions: {
                            hideBackgroundDots:true,
                            imageSize:0.4,
                            margin:0,
                            crossOrigin:"anonymous"
                        },
                        dotsOptions: {
                            type: 'square',
                            color: '#000000',
                            gradient: null
                        },
                        backgroundOptions: {
                            color: '#f6f6f6',
                            gradient: null
                        },
                        image: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_logos/Scan-Me.png`,
                        cornersSquareOptions: {
                            type: 'square',
                            color: '#000000',
                            gradient: null
                        },
                        cornersDotOptions: {
                            type: 'square',
                            color: '#000000',
                            gradient: null
                        }
                    };
                } else if (imageType === 'menu') {
                    data = {
                        width: 300,
                        height: 300,
                        margin: 0,
                        qrOptions: {
                            typeNumber:"0",
                            mode:"Byte",
                            errorCorrectionLevel:"H"
                        },
                        data: `https://lqr.ai/${key}`,
                        imageOptions: {
                            hideBackgroundDots:true,
                            imageSize:0.4,
                            margin:0,
                            crossOrigin:"anonymous"
                        },
                        dotsOptions: {
                            type: 'square',
                            color: '#000000',
                            gradient: null
                        },
                        backgroundOptions: {
                            color: '#f6f6f6',
                            gradient: null
                        },
                        image: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_logos/Menu.png`,
                        cornersSquareOptions: {
                            type: 'square',
                            color: '#000000',
                            gradient: null
                        },
                        cornersDotOptions: {
                            type: 'square',
                            color: '#000000',
                            gradient: null
                        },
                        type: 'svg'
                    };
                } else {
                    data = {
                        width: 300,
                        height: 300,
                        margin: 0,
                        qrOptions: {
                            typeNumber:"0",
                            mode:"Byte",
                            errorCorrectionLevel:"M"
                        },
                        data: `https://lqr.ai/${key}`,
                        imageOptions: {
                            hideBackgroundDots:true,
                            imageSize:0.4,
                            margin:0,
                            crossOrigin:"anonymous"
                        },
                        dotsOptions: {
                            type: 'square',
                            color: '#000000',
                            gradient: null
                        },
                        backgroundOptions: {
                            color: '#f6f6f6',
                            gradient: null
                        },
                        image:null,
                        cornersSquareOptions: {
                            type: 'square',
                            color: '#000000',
                            gradient: null
                        },
                        cornersDotOptions: {
                            type: 'square',
                            color: '#000000',
                            gradient: null
                        },
                        type: 'svg'
                    };
                };

                if (imageType === 'asset') {
                    csv.push(`https://lqr.ai/${key}`)
                } else { 
                    const qrCode = new QRCodeStyling(data);
                    qrCode.getRawData('png').then(async res => {
                        const storageRef = ref(storage, `retail/${formatted}/${key}.png`);
                        const file = new File([res], key, {type: 'image/png'});
                        await saveImgToStorage(storageRef, file);
                    });
                };
                saveToDB(key, data);
            };
            const blob = new Blob([csv.join('\n')], {type: 'text/csv'});
            setCsvLink(URL.createObjectURL(blob));
        };
    };

    if (currentUser && userObj?.admin) {
        if (userObj.admin) {
            return (
                <div className={styles.wrapper}>
                    <div className={styles.container}>
                        <div className={styles.form}>
                            <h2>Create Retail Codes</h2>
                            <div className={styles.inputGroup} style={{justifyContent: 'center'}}>
                                <label className={styles.radioLabel}>
                                    <input id='scan-label' type='radio' value='scanMe' className={styles.radio} onChange={e => setImageType(e.target.value)} checked={imageType === 'scanMe'} />
                                    {imageType === 'scanMe' ? <i className='far fa-square-check me-1' /> : <i className='far fa-square me-1' /> }
                                    Scan Me
                                </label>
                                <label className={styles.radioLabel}>
                                    <input id='menu-label' type='radio' value='menu' className={styles.radio} onChange={e => setImageType(e.target.value)} checked={imageType === 'menu'} />
                                    {imageType === 'menu' ? <i className='far fa-square-check me-1' /> : <i className='far fa-square me-1' /> }
                                    Menu
                                </label>
                                <label className={styles.radioLabel}>
                                    <input id='asset-label' type='radio' value='asset' className={styles.radio} onChange={e => setImageType(e.target.value)} checked={imageType === 'asset'} ></input>
                                    {imageType === 'asset' ? <i className='far fa-square-check me-1' /> : <i className='far fa-square me-1' /> }
                                    Liquid Label
                                </label>
                            </div>
                            <div className={styles.innerWrap}>
                                <label htmlFor='num-label'>Number of Codes</label>
                                <div className={styles.inputGroup} style={{justifyContent: 'center'}}>
                                    <input type='number' id='num-label' value={num} onChange={e => setNum(e.target.value)} onBlur={handleLabels} ></input>
                                    <button onClick={handleCreate} className={styles.btn} disabled={num <= 0}>Create</button>
                                </div>
                            </div>
                            {csvLink ?
                                <a href={csvLink} download={`${Date.now()}-csv-links.csv`} >DOWNLOAD LIQUID LABELS</a>
                            :
                                <></>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return <Navigate to='/' />;
        };
    } else {
        return <></>;
    };
};