import React, { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import SonarSwitch from '../SonarSwitch';
import API from '../../../../API';
import styles from './QRCardDetails.module.css';

const isAcceptableType = (type) => {
    const acceptable = ['image/png', 'image/jpeg', 'image/jpg'];
    return acceptable.includes(type);
};

const isAcceptableSize = (size) => {
    return size <= 5000000; // 5MB in bytes
};

export default function QRCardDetails(props) {

    const { QR, scanCount, handleFlip, childQRs, showChildren, setShowChildren } = props;
    const { storeSnapshot, updateQR, deleteSnapshot } = API;

    let qrType;
    switch (QR?.data?.qr_type) {
        case 'link':
            qrType = 'Link';
            break;
        case 'pdf':
            qrType = 'PDF';
            break;
        case 'menu':
            qrType = 'Menu';
            break;
        case 'location':
            qrType = 'Location';
            break;
        case 'microsite':
            qrType = 'Microsite';
            break;
        case 'appointment':
            qrType = 'Appointment';
            break;
        case 'tracker':
            qrType = 'Tracker';
            break;
        case 'survey':
            qrType = 'Survey';
            break;
        case 'asset':
            qrType = 'Liquid Label';
            break;
        default:
            qrType = 'Unknown';
    };

    const [ uploadLoad, setUploadLoad ] = useState(false);
    const handleUpload = async (e) => {
        if (e.target.files.length > 0) {
            setUploadLoad(true);
            const file = e.target.files[0];
            const blob = new Blob([file], {type: 'image/png'});
            
            const form = new FormData();
            form.append('snapshot', blob, 'location_snapshot.png');
    
            let data = {
                data: {}
            };
    
            if (isAcceptableType(file.type) && isAcceptableSize(file.size)) {
                await storeSnapshot(QR.id, form)
                    .then(async () => {
                        data.data.location_snapshot = `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_locations/${QR.id}/location_snapshot.png`;
    
                        await updateQR(QR.id, data)
                            .then(() => setUploadLoad(false))
                            .catch(() => console.log("Failed to update QR."));
                    })
                    .catch(() => {
                        setUploadLoad(false);
                    });
            } else {
                setUploadLoad(false);
                return;
            };
        } return;
    };

    const [ confirmDelete, setConfirmDelete ] = useState(false);
    const [ deleteLoad, setDeleteLoad ] = useState(false);
    const handleDelete = async () => {
        setDeleteLoad(true);
        await deleteSnapshot(QR.id)
            .then(() => setDeleteLoad(false))
            .catch(err => {
                console.log('Failed to delete snapshot.');
                setDeleteLoad(false);
            });
    };

    return (
        <>
        <button className={styles.btn} onClick={handleFlip}><i className='fas fa-arrow-turn-left' /></button>
        {childQRs?.length > 0 ? 
            <OverlayTrigger
                placement='top'
                delay={{show: 250, hide: 400}}
                trigger={['click','hover']}
                overlay={<Tooltip>View Linked QRs</Tooltip>}
            >
                <button className={styles.childBtn} onClick={() => setShowChildren(true)}><i className='fa-regular fa-object-exclude' /></button> 
            </OverlayTrigger>
            : <></>
        }
        <div className={styles.details}>
            <div className={styles.header}>
                <div className={styles.headerInner}><span>Details</span></div>
            </div>
            <div className={styles.divider} />
            <div className={styles.detailsBody}>
                {window.location.origin === 'https://app.liquidqr.com' ?
                    <div className={styles.scanInfo}><button className={styles.scanBtn} onClick={() => window.open(`https://lqr.ai/${QR.id}?orgId=${QR?.data?.org_id}`, '_blank')}>Scan QR</button></div>
                :
                    <div className={styles.info}>ID: <span>{QR.id}</span></div>
                }
                <div className={styles.info}>Type: <span>{qrType}</span></div>
                <div className={styles.info}>Scan Count: <span>{scanCount}</span></div>
                {QR.data.campaign ? <div className={styles.info}>Category: <span>{QR.data.category}</span></div> : <></>}
                {QR.data?.tags?.length > 0 ? 
                    <OverlayTrigger
                        placement='top'
                        delay={{show: 250, hide: 400}}
                        trigger={['click','hover']}
                        overlay={
                            <Tooltip>
                                {QR.data.tags.map((tag, i) => (
                                    <div key={i} className={styles.tag}>{tag}<span>,</span></div>
                                ))}
                            </Tooltip>
                        }
                    >
                        <div className={styles.overlayTrigger}>View Tags</div>
                    </OverlayTrigger> 
                    :
                    <></>
                }
                {QR.data?.location_snapshot && QR.data?.location_snapshot !== null ?
                    <div className={styles.snapshotWrap}>
                        {!confirmDelete ?
                            <button className={styles.snapshotDelete} onClick={() => setConfirmDelete(true)} title='Remove Snapshot'><i className='fas fa-trash-can' /></button>
                            :
                            <div className={styles.snapDeleteWrap}>
                                <button className={styles.snapDeleteCancel} onClick={() => setConfirmDelete(false)} disabled={deleteLoad}><i className='far fa-xmark' />Cancel</button>
                                <button className={styles.snapDeleteConfirm} onClick={handleDelete} disabled={deleteLoad}>{!deleteLoad ? <><i className='fas fa-check' />Confirm</> : <i className='fas fa-spinner fa-spin' style={{fontSize: '1rem', color: '#161d2d'}} />}</button>
                            </div>
                        }
                        <a href={`${QR.data.location_snapshot}?${Date.now()}`} className={styles.snapshotBtn} target='_blank' rel="noreferrer">View Location</a>
                    </div>
                    :
                    <>
                    <input type='file' id={`snap-upload-${QR.id}`} className={styles.snapshotUpload} accept='image/png, image/jpeg, image/jpg' onChange={handleUpload} disabled={uploadLoad} />
                    <label htmlFor={`snap-upload-${QR.id}`} className={styles.snapshotUploadBtn}>{!uploadLoad ? 'Upload Location' : <i className='fas fa-spinner fa-spin' />}</label>
                    </>
                }
                <SonarSwitch QR={QR} />
            </div>
        </div>
        
        </>
    );
};