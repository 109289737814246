import React, { useState } from 'react';
import LoginComponent from '../../components/LoginComponent';
import { useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../../utils/Auth';
import styles from './Login.module.css';

export default function Login() {
    // ----- STATE INITIALIZATION -----
    const { login, currentUser } = useAuth();
    
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ error, setError ] = useState(false);

    const navigate = useNavigate();
    const query = window.location.search;
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- LOGIN FUNCTION -----
    const handleLogin = async (e) => {
        e.preventDefault();
        await login(email, password)
            .then(() => {
                if (query) {
                    window.location.assign(window.location.origin + `/upgrade${query}`);
                };

                navigate('/home');
            })
            .catch(() => {
                setError(true);
            });
    };
    // ----- END LOGIN FUNCTION -----
    // ----------------------------------------------

    if (currentUser) {
        return (<Navigate replace to='/home' />);
    } else {
        return (
            <div className={styles.wrapper}>
                <div className={styles.container}>
                    <h2 className='mb-2'>Login</h2>
                    <LoginComponent handleLogin={handleLogin} email={email} setEmail={setEmail} password={password} setPassword={setPassword} error={error} setError={setError} />
                </div>
            </div>
        );
    };
};