import React, { useState, useEffect } from 'react';
import styles from './AssetOptions.module.css';

const FileSelector = (props) => {
    const { qrFiles, setQrFiles, filesToDisplay, setFilesToDisplay } = props;

    const [ error, setError ] = useState('');
    const acceptableFileTypes = ['image/png', 'image/gif', 'image/jpeg', 'image/jpg', 'application/pdf', 'video/mp4', 'video/webm', 'video/quicktime'];
    
    const handleFileUpload = async (e) => {
        const filesArr = Object.values(e.target.files);
        const uploadArr = [];
        
        if ((filesToDisplay.length + filesArr.length) > 5) {
            setError('Maximum of 5 files allowed.');
            return;
        };
        
        const promiseReader = async (file) => {
            return new Promise((resolve) => {
                const reader = new FileReader();
                reader.onload = (e) => {
                    resolve(e.target.result);
                };
                reader.readAsDataURL(file);
            });
        };
        
        for (const file of filesArr) {
            if (!acceptableFileTypes.includes(file.type) || file.size > 5000000) {
                setError(!acceptableFileTypes.includes(file.type) ? 'File type not accepted.' : 'File size exceeds 5MB. File must be under 5MB.');
            } else {
                uploadArr.push({ url: await promiseReader(file), raw: file });
            };
        };
        const date = Date.now()
        setQrFiles(prev => (prev.concat(...filesArr.map((f,i) => ({ data: f, name: `${date + i}` })))));
        setFilesToDisplay(prev => (prev.concat(...uploadArr)));
    };

    return (
        <>
        <h5 className='mt-3'>Upload Files</h5>
        <div className={styles.fileUpload} >
            <input type='file' id='asset-file-label' className={styles.fileInput} multiple accept={acceptableFileTypes.join(',')} onChange={e => { setError(''); handleFileUpload(e); }} disabled={qrFiles?.length >= 5} ></input>
            <label htmlFor='asset-file-label' className={styles.fileLabel}>Add File(s)</label>
        </div>
        {error ?
            <p style={{color: 'red'}}>{error}</p>
        :
            <></>
        }
        <div style={{lineHeight: '1.5rem', whiteSpace: 'nowrap', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis'}} className='mb-1'>Files Slot(s) Remaining: <span style={{fontWeight: 'bold'}}>{5 - Number(filesToDisplay?.length ?? 0)}</span></div>
        </>
    );
};

const FileDisplay = (props) => {
    const { setQrFiles, filesToDisplay, setFilesToDisplay, setDeleteFiles } = props;

    const handleBuildRoute = (route) => {
        if (route?.includes('pdf')) {
            return (
                <>
                    <span className='mb-0'>{route.split('.')[1]}</span>
                    <i className={route.includes('pdf') ? `fa-solid fa-file-pdf ${styles.display_icon}` : `fa-solid fa-image ${styles.display_icon}`} />
                </>
            );
        };

        if (route?.includes('base64')) {
            return <img src={route} className={styles.thumbnail} alt='QR code to upload' ></img>
        };

        return <img src={`https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/qr_files/${route}?${Date.now()}`} className={styles.thumbnail} alt='QR code' ></img>
    };

    const handleRemoveAsset = (route) => {
        if (route?.name) {
            setFilesToDisplay(prev => prev.filter(obj => obj.raw.name !== route.name));
            setQrFiles(prev => prev.filter(file => file.name && (file?.name !== route?.name)));
        } else {
            setFilesToDisplay(prev => prev.filter(obj => obj.url !== route && obj.raw !== route));
            setDeleteFiles(prev => prev.concat(route));
        };
    };
    
    if (filesToDisplay?.length < 1) {
        return <></>;
    };

    return (
        <div className={styles.file_display}>
            {filesToDisplay?.length > 0 ?
                filesToDisplay.map((file,i) => (
                    <div className={styles.file_container} key={i}>
                        {file?.url ? handleBuildRoute(file?.url) : <></>}
                        <button className={styles.remove_menu} onClick={() => handleRemoveAsset(file?.raw)}><i className='fa-solid fa-xmark' /></button>
                    </div>
                ))
            :
                <></>
            }
        </div>
    );
};

const AssetOptions = (props) => {
    const { qr_data, qrFiles, setQrFiles, setDeleteFiles } = props;

    const [ filesToDisplay, setFilesToDisplay ] = useState([]);
    // ----- END STATE INITIALIZATION -----
    // ----------------------------------------------

    // ----- ONLOAD AND UPDATE -----
    useEffect(() => {
        if (qr_data?.asset?.route?.length > 0) {
            setFilesToDisplay(qr_data.asset.route.map(file => ({url: file, raw: file})));
        };
    },[qr_data?.asset]);
    // ----- END ONLOAD AND UPDATE -----
    // ----------------------------------------------

    return (
        <div className={styles.container}>
            <FileSelector qrFiles={qrFiles} setQrFiles={setQrFiles} filesToDisplay={filesToDisplay} setFilesToDisplay={setFilesToDisplay} />
            <FileDisplay setQrFiles={setQrFiles} filesToDisplay={filesToDisplay} setFilesToDisplay={setFilesToDisplay} setDeleteFiles={setDeleteFiles} />
        </div>
    );
};

export default AssetOptions;